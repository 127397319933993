import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import { Url } from '../Utils';

const DomainModal = (props) => {
    const [show, changeShow] = useState(false)
    const { domainNames } = props;
    return (
        <>
            <Button onClick={()=>{ changeShow(true) }} className="mx-2" variant="light" size="">Show domains</Button>
            <Modal show={show} onHide={() => changeShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Associated Domains ({domainNames.length})</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ListGroup>
                    {
                        domainNames.map((name, index) => 
                            <ListGroup.Item key={index}>
                                <a href={Url('Site/' + name)}>
                                {name}
                                </a>
                            </ListGroup.Item>
                        )
                    }
                    </ListGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => changeShow(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DomainModal;