import React, {useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Modal, Button } from 'react-bootstrap'
import { GetText } from '../../Lang'
import Form from 'react-bootstrap/Form'
import WebsiteRank from '../../embed/WebsiteRank'
import { ParseHost, SendHttpRequest } from '../../Utils'

const embedCode = '<iframe frameBorder="0" src="https://' + window.location.host + '/Embed/WebsiteRank"></iframe>';
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CopyToClipboard = (e) => {
    e.preventDefault();
    var copyText = document.getElementById("embedCode");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
}

function renderTooltipCopy(props) {
    return <Tooltip {...props} show={props.show.toString()}>{GetText('getCodeSnippetPopup', 'tooltips', 'clickToCopy')}</Tooltip>;
}

function renderTooltipCopied(props) {
    return <Tooltip {...props} show={props.show.toString()}>{GetText('getCodeSnippetPopup', 'tooltips', 'copied')}</Tooltip>;
}

const GetCodeSnippet = (props) => {
    const [saveErrorOccurred, setSaveErrorOccurred] = useState(false)
    const [codeGenerated, setCodeGenerated] = useState(false)
    const [showCopied, setShowCopied] = useState(false)
    const [website, setWebsite] = useState('')
    const [email, setEmail] = useState('')
    const [isInitial, setIsInitial] = useState(true)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const websiteParsed = ParseHost(website)
    let inputValid = isEmailValid && websiteParsed
    return (
        <>
            <Modal show={props.show} onHide={() => {
                props.setShow(false)
            }}>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">{GetText('getCodeSnippetPopup', 'labels', 'email')}</label>
                        <Form.Control isInvalid={!isEmailValid && !isInitial} type="email" className="form-control" required placeholder="example@mailprovider.com" value={email} 
                            onChange={(event) => {
                                setIsEmailValid(emailRegex.test(event.target.value));
                                setEmail(event.target.value);
                            }} />
                    </div>
                    <div className="form-group">
                        <label className="form-label">{GetText('getCodeSnippetPopup', 'labels', 'website')}</label>
                        <Form.Control isInvalid={!websiteParsed && !isInitial} type="text" className="form-control" required placeholder="https://yoursite.com" value={website} 
                            onChange={(event) => {
                                setCodeGenerated(false);
                                setWebsite(event.target.value);
                            }} />
                    </div>
                    <label className="form-label">{GetText('getCodeSnippetPopup', 'labels', 'preview')}</label>
                    <div className={"bg-light p-2 text-center"}>
                    {
                        websiteParsed ?
                        <>
                            <WebsiteRank preview={true} host={websiteParsed}/>
                        </>
                        :
                        (
                            isInitial ?
                            <span className="text-muted">{GetText('getCodeSnippetPopup', 'explanations', 'previewStartTyping')}</span>
                            :
                            <span className="text-muted">{GetText('getCodeSnippetPopup', 'explanations', 'previewNotAvailable')}</span>
                        )
                    }
                    </div>
                    {
                        codeGenerated && 
                        <>
                            <br/>
                            <label>{GetText('getCodeSnippetPopup', 'explanations', 'copySnippet')}</label>
                            <textarea cols="3" id="embedCode" className="form-control" defaultValue={embedCode}>
                            </textarea>
                        </>
                    }
                    
                    {
                        saveErrorOccurred &&
                        <div className="mt-2 alert alert-danger" role="alert">
                        {GetText('generic', 'requestErrorOccurred')}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        props.setShow(false);
                    }}>
                        {GetText('getCodeSnippetPopup', 'buttons', 'cancel')}
                    </Button>
                    {
                        !codeGenerated &&
                        <Button variant="primary" onClick={(e) => {
                            setIsInitial(false);
                            if (inputValid) {
                                SendHttpRequest('AddEmbedCodeGenerationEvent', { method: "POST", body: JSON.stringify({
                                    email: email,
                                    host: websiteParsed,
                                    website: website,
                                    type: 'rank'
                                })}).then(function(data) {
                                    if (data.errorCode) {
                                        setSaveErrorOccurred(true);
                                    }
                                    else {
                                        setSaveErrorOccurred(false);
                                        setCodeGenerated(true);
                                    }
                                });
                            }
                        }}>
                            {GetText('getCodeSnippetPopup', 'buttons', 'generate')}
                        </Button>
                    }
                    {
                        codeGenerated &&
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 0, hide: 500 }}
                            overlay={showCopied ? renderTooltipCopied : renderTooltipCopy}
                            onExited={() => setShowCopied(false)}
                        >
                            <Button variant="primary" 
                                onClick={(e) => { 
                                    setShowCopied(!showCopied); 
                                    CopyToClipboard(e);
                                    
                                    setTimeout(() => {
                                        props.setShow(false);
                                    }, 300);
                                }}
                            >
                                {GetText('getCodeSnippetPopup', 'buttons', 'copy')}
                            </Button>
                        </OverlayTrigger>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GetCodeSnippet;