import React from 'react'

const Spinner = (props) => {

    return (
        <div className={"text-center my-2 " + props.className}>
            <div className="spinner-grow" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
}

export default Spinner;