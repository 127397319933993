import LocalizedStrings from 'react-localization';
import enabout from './translations/en/about.json'
import engeneric from './translations/en/generic.json'
import ensite from './translations/en/site.json'
import ensearch from './translations/en/search.json'
import enhome from './translations/en/home.json'
import enhappening from './translations/en/happening.json'
import enmenu from './translations/en/menu.json'
import enfooter from './translations/en/footer.json'
import encountries from './translations/en/countries.json'
import encontact from './translations/en/contact.json'
import encareers from './translations/en/careers.json'
import enprivacy from './translations/en/privacy.json'
import encookies from './translations/en/cookies.json'
import enstart from './translations/en/start.json'
import enPageNotFound from './translations/en/pageNotFound.json'
import enThankYou from './translations/en/thankYou.json'
import enEmbed from './translations/en/embed.json'
import enGetCodeSnippetPopup from './translations/en/getCodeSnippetPopup.json'

export const Lang = new LocalizedStrings({
    en: {
        about: enabout,
        site: ensite,
        search: ensearch,
        home: enhome,
        happening: enhappening,
        generic: engeneric,
        menu: enmenu,
        countries: encountries,
        contact: encontact,
        footer: enfooter,
        careers: encareers,
        privacy: enprivacy,
        cookies: encookies,
        start: enstart,
        pageNotFound: enPageNotFound,
        thankYou: enThankYou,
        embed: enEmbed,
        getCodeSnippetPopup: enGetCodeSnippetPopup
    }
});

export const GetText = (key1, key2, key3, key4) => {
    if (key1 && key2 && key3 && key4) {
        if (Lang[key1] && Lang[key1][key2] && Lang[key1][key2][key3] && Lang[key1][key2][key3][key4]) {
            return Lang[key1][key2][key3][key4];
        }
        return key1 + '.' + key2 + '.' + key3 + '.' + key4;
    }
    if (key1 && key2 && key3) {
        if (Lang[key1] && Lang[key1][key2] && Lang[key1][key2][key3]) {
            return Lang[key1][key2][key3];
        }
        return key1 + '.' + key2 + '.' + key3;
    }
    if (key1 && key2) {
        if (Lang[key1] && Lang[key1][key2]) {
            return Lang[key1][key2];
        }
        return key1 + '.' + key2;
    }
    if (key1) {
        if (Lang[key1]) {
            return Lang[key1];
        }
        return key1;
    }
    return "";
}
