import React from 'react'
import StandardContent from '../comp/StandardContent'
import { GetText } from '../Lang'
  
const ThankYou = () => {
  return (
    <StandardContent>
        <h1>{GetText('thankYou', 'header')}</h1>
        <p className="lead">{GetText('thankYou', 'explanation')}</p>
    </StandardContent>
  );
}

export default ThankYou;