import React, {useState, useEffect} from 'react';
import { Button, Row, Col } from 'react-bootstrap'
import { SendHttpRequest, Url, SetDocumentTitleAndMeta } from '../Utils'
import { GetText } from '../Lang.js'
import { FormatDecimal, FormatString, FormatNumberOfViews } from '../cutils'
import { TimeRangeLast7Days, TimeRangeMonth, TimeRangeYear, TimeRangeAll } from '../cdefines.js'
import Hero from '../comp/Hero';
import StandardContent from '../comp/StandardContent'
import Spinner from '../comp/Spinner'
import DomainModal from '../modals/DomainModal'

const fetchSiteData = (host, timeRange, setData) => {
    SendHttpRequest('GetWebsiteData', { method: "POST", body: JSON.stringify({
        host: host,
        timeRange: timeRange
    })}).then(function(data) {
        setData(data);
    });
}

const ReviewCol = (id, object) => {
    return <Col>
        <label>{GetText('generic', 'criteria', id)} ({FormatDecimal(object.score)})</label>
        {
            object.tendency > 0 &&
            <span className="badge badge-success float-right">+{FormatDecimal(object.tendency)}%</span>
        }
        {
            object.tendency < 0 &&
            <span className="badge badge-danger float-right">{FormatDecimal(object.tendency)}%</span>
        }
        <div className="progress" data-toggle="tooltip" title="blasllal">
            <div className="progress-bar dynamic" style={{width: object.score * 10 + '%'}} role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </Col>;
}

const Site = (props) => {
    const [timeRange, setTimeRange] = useState(TimeRangeLast7Days)
    const [data, setData] = useState({})
    const [host] = useState(props.match.params.host)
    useEffect(() => SetDocumentTitleAndMeta('site', [host], [host]));
    useEffect(() => {
        setData({});
        fetchSiteData(host, timeRange, setData);
    }, [host, timeRange])
    return (
        <>
            <Hero>
                <h1>
                    {
                        data.faviconUrl && 
                        <img style={{'maxHeight':'40px', 'top': '2px', 'position': 'relative'}} className="mr-3 "src={data.faviconUrl} alt={host}/>
                    }
                    {host}
                </h1>
                { 
                    typeof data.number === 'undefined' ? '' : 
                    <p className="lead">
                        {FormatString(GetText('site', 'explanations', 'lastReviewOn'), [new Date(data.lastReviewedOn * 1000).toLocaleString()])}<br/>
                        {FormatString(GetText('site', 'explanations', 'totalReviews'), [data.totelNumberOfConfirmedReviews, data.domainNames.length])}<br/>
                    </p>
                }
                {
                    data.number && data.domainNames && data.domainNames.length > 1 ? <DomainModal domainNames={data.domainNames}/> : ''
                }
                <Button href={Url('Search')} variant="outline-light" size="">{GetText('site', 'buttons', 'newSearch')}</Button>
            </Hero>
            <StandardContent>
            {
                <>
                <div id="timeRangeActions" className="mt-3 btn-group d-flex">
                {[ TimeRangeLast7Days, TimeRangeMonth, TimeRangeYear, TimeRangeAll ].map((selectedTimeRange, index) => 
                    <button key={selectedTimeRange} data-toggle="tooltip" type="button" className={(selectedTimeRange === timeRange) ? 'btn btn-light active' : 'btn btn-light'} role={selectedTimeRange} onClick={(event) => {
                        setTimeRange(selectedTimeRange);
                    }}>
                        {GetText('site', 'buttons', 'timeRange', selectedTimeRange)}
                    </button>
                )}
                </div>
                <hr/>
                { typeof data.number !== 'undefined' ?
                    <>
                        <Row>
                            <Col className="metric text-center">
                                <p className="number m-0">
                                    { (typeof data.averageRating === 'undefined') ? 'n/a' : (FormatDecimal(data.averageRating) + ' / 10')}
                                    {
                                        (typeof data.reviewTendencyPercentage !== 'undefined') && data.reviewTendencyPercentage > 0 &&
                                        <span data-toggle="tooltip" className="badge badge-success">+{FormatDecimal(data.reviewTendencyPercentage)}%</span>
                                    }
                                    {
                                        (typeof data.reviewTendencyPercentage !== 'undefined') && data.reviewTendencyPercentage < 0 &&
                                        <span data-toggle="tooltip" className="badge badge-danger">{FormatDecimal(data.reviewTendencyPercentage)}%</span>
                                    }
                                </p>
                                <p className="label text-muted">{GetText('site', 'labels', 'averageScore')}</p>
                            </Col>
                            <Col className="metric text-center">
                                <p className="number m-0">{FormatNumberOfViews(data.number)}</p>
                                <p className="label text-muted">{GetText('site', 'labels', 'numberOfReviews')}</p>
                            </Col>
                        </Row>

                        <hr/>
                        { 
                            data.number > 0 ? 
                            <>
                            <div className="header text-center">
                                <p className="text-muted" dangerouslySetInnerHTML={{__html: FormatString(GetText('site', 'explanations', 'ratingOfAt'), [host])}}></p>
                            </div>

                            <Row className="mt-3">
                                { ReviewCol('accessibility', data.siteReviews['accessibility']) }
                                { ReviewCol('completeness', data.siteReviews['completeness']) }
                            </Row>

                            <Row className="mt-3">
                                { ReviewCol('accuracy', data.siteReviews['accuracy']) }
                                { ReviewCol('usefulness', data.siteReviews['usefulness']) }
                            </Row>

                            <Row className="mt-3">
                                { ReviewCol('engagement', data.siteReviews['engagement']) }
                                { ReviewCol('recommend', data.siteReviews['recommend']) }
                            </Row>
                            </>
                            :
                            <div className="header text-center">
                                <p className="text-muted" dangerouslySetInnerHTML={{__html: FormatString(GetText('site', 'explanations', 'noOneRated'), [host])}}></p>
                            </div>
                        }

                        <hr/><br/>
                        <div className="row">
                            <div className="col text-muted text-center">
                                <p className="lead">{GetText('site', 'explanations', 'reviewerNote')}</p>
                                <Button href={Url("Start")} >{GetText('site', 'buttons', 'rate')}</Button>
                            </div>
                            <div className="col text-muted text-center">
                                <p className="lead">{GetText('site', 'explanations', 'ownerNote')}</p>
                                <Button href={Url("Embed")} >{GetText('site', 'buttons', 'embed')}</Button>
                            </div>
                        </div>
                    </>
                :
                <Spinner/>
                }
            </>
            }
            </StandardContent>
        </>
    )
}

export default Site