export const DefaultLang = 'en';

export const SupportedLanguages = [
    {
        lang: 'en', 
        label: 'English'
    }
];

export const GlobalHttpRequestTimeout = 5000;

export const ChromeExtensionUrl = 'https://chrome.google.com/webstore/detail/w3rated-website-ranking-c/aeijmmnnkdhboafjnffkoihoceafbecm?authuser=1'