import React, {useEffect} from 'react'
import { Button, Carousel, Image } from 'react-bootstrap'
import { Url, SetDocumentTitleAndMeta} from '../Utils'
import { GetText } from '../Lang.js'
import Try from '../comp/Try'
import ImageTextRow from '../comp/RowTwoCols/ImageTextRow'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

const DimensionsSection = (dimension, reversed=false) => {
  return <ImageTextRow 
      imgColMd={2}
      textColMd={10}
      header={GetText('home', 'dimensionHeaders', dimension)}
      lead={GetText('home', 'dimensionTexts', dimension)}
      imageProps={{height: '150px', src: '/images/icon-' + dimension + '-filled.png'}}
      reversed={false}
    />;
}

const Home = () => {
  useEffect(() => SetDocumentTitleAndMeta('home'));
  return (
    <>
      <section className="intro py-3">
        <div className="container">
          <div className="row">
            <Zoom>
              <div className="text-white col-md-6 py-5">
                <h1>{GetText('home', 'h1')}</h1>
                <p className="lead text-justify" dangerouslySetInnerHTML={{__html: GetText('home', 'lead')}}></p>
                <Button href={Url('Search')} className="mt-2" variant="light" size="lg">{GetText('home', 'leadButton')}</Button>
              </div>
            </Zoom>
            
            <Fade right>
              <div className="col-md-6 py-5">
              <Carousel interval={2000} indicators={false} controls={false}>
                <Carousel.Item>
                  <img
                    className="rounded shadow d-block w-100"
                    src="/images/slider-google.png"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="rounded shadow d-block w-100"
                    src="/images/slider-theguardian.png"
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="rounded shadow d-block w-100"
                    src="/images/slider-facebook.png"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="my-3 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md text-center my-3">
              <div className="mb-4"><Image height={'80px'} src="/images/outline1.png" alt={GetText('home', 'outlineHeaders', 'outline1')}/></div>
              <h5 className="font-weight-bold">{GetText('home', 'outlineHeaders', 'outline1')}</h5>
              <p style={{minHeight: '130px'}}>{GetText('home', 'outlineTexts', 'outline1')}</p>
            </div>
            <div className="col-md text-center my-3">
              <div className="mb-4"><Image height={'80px'} src="/images/outline2.png" alt={GetText('home', 'outlineHeaders', 'outline2')}/></div>
              <h5 className="font-weight-bold">{GetText('home', 'outlineHeaders', 'outline2')}</h5>
              <p style={{minHeight: '130px'}}>{GetText('home', 'outlineTexts', 'outline2')}</p>
            </div>
            <div className="col-md text-center my-3">
              <div className="mb-4"><Image height={'80px'} src="/images/outline3.png" alt={GetText('home', 'outlineHeaders', 'outline3')}/></div>
              <h5 className="font-weight-bold">{GetText('home', 'outlineHeaders', 'outline3')}</h5>
              <p style={{minHeight: '130px'}}>{GetText('home', 'outlineTexts', 'outline3')}</p>
            </div>
          </div>
        </div>
      </section>

      <hr/>

      <section className="my-3">
        <div className="container">
          <h2 className="text-center">{GetText('home', 'headers', 'sixDimensionalReview')}</h2>
          <p className="lead text-center">{GetText('home', 'explanations', 'sixDimensionalReview')}</p>
          { DimensionsSection('accuracy', true) }
          { DimensionsSection('completeness', false) }
          { DimensionsSection('accessibility', true) }
          { DimensionsSection('usefulness', false) }
          { DimensionsSection('engagement', true) }
          { DimensionsSection('recommend', false) }
        </div>
      </section>
      
      <Try/>
    </>
  );
}

export default Home;