
import React, {useState, useEffect} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { Url } from '../Utils'
import State from '../State'
import { SupportedLanguages, DefaultLang } from '../Defines'
import LogoDark from '../icons/logo_dark.png'
import LogoWhite from '../icons/logo_white.png'
import { GetText } from '../Lang'

const Navigation = (props) => {
    const [dark, setDark] = useState(false);
    const handleScroll = (event) => {
        if (event.srcElement.documentElement.scrollTop > 50) {
            setDark(true);
        }
        else {
            setDark(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [dark]);
    return (
        <Navbar bg={dark ? 'dark' : 'light'} variant={dark ? 'dark' : 'light'} className="shadow-sm sticky-top" expand="lg">
            <div className="container">
                <Navbar.Brand href={Url('')} className="font-weight-bold mr-5">
                    <img
                        src={dark ? LogoWhite : LogoDark}
                        width="30"
                        height="30"
                        className="d-inline-block align-top mr-3"
                        alt="React Bootstrap logo"
                    />
                    W3RATED
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto container font-weight-bold">
                        <Nav.Link href={Url('')} className={props.homeActive + " px-3"}>{GetText('menu', 'home')}</Nav.Link>
                        <Nav.Link href={Url('About/')} className={props.aboutActive + " px-3"}>{GetText('menu', 'about')}</Nav.Link>
                        <Nav.Link href={Url('Search/')} className={props.searchActive + " px-3"}>{GetText('menu', 'search')}</Nav.Link>
                        <Nav.Link href={Url('Start/')} className={props.startActive + " px-3"}>{GetText('menu', 'start')}</Nav.Link>
                        <Nav.Link href={Url('Embed/')} className={props.embedActive + " px-3"}>{GetText('menu', 'embed')}</Nav.Link>
                    </Nav>
                    {SupportedLanguages.length > 1 &&
                    <DropdownButton id="locale-button" variant="link" className="mr-1" title={State.lang.toUpperCase()}>
                    {
                        SupportedLanguages.map((object, index) => {
                            let pathname = window.location.pathname;
                            if (pathname.startsWith('/en') || pathname.startsWith('/de')) {
                                pathname =  pathname.substr(3);
                            }
                            if (object.lang !== DefaultLang) {
                                pathname = '/' + object.lang + pathname;
                            }
                            return <Dropdown.Item href={pathname} key={object.lang} active={State.lang === object.lang}>{object.label}</Dropdown.Item>
                        }
                        )
                    }
                    </DropdownButton>
                    }
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}

export default Navigation;