import React, {useEffect, useState} from 'react'
import { Button, Image } from 'react-bootstrap'
import { GetText } from '../Lang'
import { SetDocumentTitleAndMeta } from '../Utils'
import GetCodeSnippet from './embed/GetCodeSnippet';

const Embed = (props) => {

    const [show, setShow] = useState(props.show)
    useEffect(() => SetDocumentTitleAndMeta('embed'))
    
    return (
        <>
            <section className="my-3">
                <div className="container">
                    <div className="text-center">
                        <br/><br/>
                        <Image className="my-2" height="100px" src={'/images/embed.png'}/>
                        <br/><br/>
                        <h1>{GetText('embed', 'headers', 'service')}</h1>
                        <p className="lead">{GetText('embed', 'explanations', 'service')}</p>

                        <GetCodeSnippet show={show} setShow={setShow}/>
                        <Button onClick={() => {
                            setShow(true)
                        }} size={'lg'} className="my-3" target={'_blank'}>{GetText('embed', 'buttons', 'getcodesnippet')}</Button>
                    </div>
                </div>
            </section>
            </>
    );
}

export default Embed;