import React, {useEffect} from 'react'
import ImageTextRow from '../comp/RowTwoCols/ImageTextRow'
import { SetDocumentTitleAndMeta } from '../Utils'
import { GetText } from '../Lang'
import Try from '../comp/Try'

const About = () => {
  useEffect(() => SetDocumentTitleAndMeta('about'));

  return (
    <>
      <section className="my-3">
        <div className="container">
          <br/>
          <br/>
          <ImageTextRow 
              imgColMd={6}
              textColMd={6}
              header={GetText('about', 'headers', 'about')}
              lead={GetText('about', 'explanations', 'about')}
              imageProps={{width: '100%', src: '/images/dailymail.png'}}
              reversed={true}
            />
          <br/>
          <ImageTextRow
              imgColMd={6}
              textColMd={6}
              header={GetText('about', 'headers', 'mission')}
              lead={GetText('about', 'explanations', 'mission')}
              imageProps={{width: '50%', src: '/images/our-mission.png'}}
          />
        </div>
      </section>
      <Try/>
    </>
  );
}

export default About;