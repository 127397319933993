
import React from 'react'

const Hero = (props) => {
    return (
      <section className="intro">
        <div className="container">
            <div className="row justify-content-center">
                <div className="text-white text-center col-md-8 py-5">
                    {props.children}
                </div>
            </div>
        </div>
        </section>
    );
}

export default Hero;