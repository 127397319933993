import React, {useEffect} from 'react'
import { SetDocumentTitleAndMeta } from '../Utils'
import { GetText } from '../Lang'
import StandardContent from '../comp/StandardContent'

const Cookies = () => {
  useEffect(() => SetDocumentTitleAndMeta('cookies'));
  return (
    <StandardContent>
        <h1>{GetText('cookies', 'h1')}</h1>
        <b>{GetText('cookies', 'explanations', 'lastupdate')}</b><br/><br/>
        <p>{GetText('cookies', 'explanations', 'intro')}</p>
        
        <h4><b>{GetText('cookies', 'headers', 'whatiscookie')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'whatiscookie')}</p>
        
        <h4><b>{GetText('cookies', 'headers', 'howweuse')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'howweuse')}</p>

        <ul>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '1')}}></li>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '2')}}></li>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '3')}}></li>
        </ul>

        <h4><b>{GetText('cookies', 'headers', 'manage')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'manage1')}</p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target='_blank' rel="noopener noreferrer"><b>Google Chrome</b></a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target='_blank' rel="noopener noreferrer"><b>Firefox</b></a>
          </li>
          <li>
            <a href="https://www.cookiesandyou.com/disable-cookies/windows/edge/" target='_blank' rel="noopener noreferrer"><b>Edge</b></a>
          </li>
          <li>
            <a href="https://support.apple.com/en-am/guide/safari/sfri11471/mac" target='_blank' rel="noopener noreferrer"><b>Saferi</b></a>
          </li>
          <li>
            <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/" target='_blank' rel="noopener noreferrer"><b>Opera</b></a>
          </li>
        </ul>
        <h4><b>{GetText('cookies', 'headers', 'updates')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'updates')}</p>
    </StandardContent>
  );
}

export default Cookies;