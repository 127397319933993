import React, {useEffect} from 'react'
import { SetDocumentTitleAndMeta } from '../Utils'
import { ChromeExtensionUrl } from '../Defines'
import { GetText } from '../Lang'
import { Button, Image } from 'react-bootstrap'

const Start = () => {
  useEffect(() => SetDocumentTitleAndMeta('start'));

  return (
    <>
      <section className="my-3">
        <div className="container">
          <div className="text-center">

            <br/><br/>
            <a href={ChromeExtensionUrl} target="_blank" without="true" rel="noopener noreferrer"><Image className="my-2" height="100px" src={'/images/chrome-store.png'}/></a>
            <br/><br/>
            <h1>{GetText('start', 'headers', 'service')}</h1>
            <p className="lead">{GetText('start', 'explanations', 'service')}</p>
            <br/><br/>
            <Image width={'100%'} src={'/images/chrome-toolbar.png'}/>
            <br/>
            <Button size={'lg'} className="my-4" href={ChromeExtensionUrl} target={'_blank'}>{GetText('start', 'buttons', 'download')}</Button>
          </div>
          <br/>
        </div>
      </section>
    </>
  );
}

export default Start;