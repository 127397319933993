import React, { useState, useEffect } from 'react'
import { IdentifyEmbedHost, SendHttpRequest } from '../Utils'
import { FormatDecimal } from '../cutils'

const fetchSiteData = (host, setData) => {
    SendHttpRequest('GetWebsiteScore', { method: "POST", body: JSON.stringify({
        host: host
    })}).then(function(data) {
        setData(data);
    }).catch(() => {});
}

const WebsiteRank = (props) => {
    document.body.style.background = "none";
    const [data, setData] = useState({});
    let host = props.preview ? props.host : IdentifyEmbedHost();
    useEffect(() => {
        if (host !== null) {
            fetchSiteData(host, setData);
        }
    }, [host]);

    return (
        host &&
        <div className="score-embed metric text-center">
            {
                typeof data.averageRating !== 'undefined' &&
                <p className="number m-0">
                    { FormatDecimal(data.averageRating) + ' / 10' }
                </p>
            }
            <p className="label mb-1">
                <a target="parent" href={"https://w3rated.com/Site/" + host} title={"Website Rank of " + host} className="btn btn-dark badge">
                    {host}
                </a>
            </p>
            <p className="m-0 small text-muted">
                Rank by <a target="parent" href="https://w3rated.com">w3rated.com</a>
            </p>
        </div>
    );
}

export default WebsiteRank;