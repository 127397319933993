import React from 'react'
import Button from 'react-bootstrap/Button'
//import { Image, Carousel } from 'react-bootstrap'
import { GetText } from '../Lang'
import { Url } from '../Utils'

const Try = (props) => {

    return (
        <section className="my-3">
        <div className="container">
          <h2 className="text-center my-5">{GetText('generic', 'try', 'header')}</h2>
            {/*
                <Carousel 
                    controls={false}
                    interval={2000}
                  >
                <Carousel.Item>
                <div className="row">
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/mit.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/wework.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/carlsberg.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/mit.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/carlsberg.png"/>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="row">
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/mit.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/wework.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/carlsberg.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/mit.png"/>
                    </div>
                    <div className="col">
                      <Image width="180px" src="https://res.cloudinary.com/dapulse/image/upload/f_auto/remote_mondaycom_static/img/customers/carlsberg.png"/>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            */}
        </div>
        <div className="container text-center">
            <Button href={Url('Start')} size={'lg'}>{GetText('generic', 'try', 'button')}</Button>
        </div>
      </section>
    );
}

export default Try;