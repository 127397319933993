import React from 'react'
import './App.css'
import './bootstrap.css'
import './common.css'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import Home from './pages/Home'
import Search from './pages/Search'
import Site from './pages/Site'
import About from './pages/About'
import HappeningNow from './pages/HappeningNow'
import Navigation from './comp/Navigation'
import Footer from './comp/Footer'
import PageNotFound from './pages/PageNotFound'
import MyToast from './comp/MyToast'
import State from './State'
import Contact from './pages/Contact'
import Careers from './pages/Careers'
import { Lang, GetText } from './Lang'
import { SupportedLanguages } from './Defines'
import Privacy from './pages/Privacy'
import Cookies from './pages/Cookies'
import Start from './pages/Start'
import ThankYou from './pages/ThankYou'
import Embed from './pages/Embed'
import WebsiteRank from './embed/WebsiteRank'
import GoogleAnalytics from './comp/GoogleAnalytics'

class App extends React.Component {

  constructor(props) {
    super(props)
    this.constructState()

    let langCodes = [];
    SupportedLanguages.map((item, index) => {
      langCodes.push(item.lang);
      return 0;
    });
    this.langRegex = '(' + langCodes.join('|') + ')';
  }

  constructState() {
    if (this.props.match.match && this.props.match.match.params) {
      State.lang = this.props.match.match.params[0];
    }
  }

  componentWillMount() {
    Lang.setLanguage(State.lang);
  }

  render() {
    return (
        <div>
          <BrowserRouter>
            <Switch>

              <Route exact path={"/" + this.langRegex + "?/"} children={(match) => (
                <>
                  <GoogleAnalytics></GoogleAnalytics>
                  <MyToast message={GetText('generic', 'useOfCookies')}/>
                  <Navigation homeActive="active"/>
                  <Home/>
                  <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Search/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation searchActive="active"/>
                <Search/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/HappeningNow/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation happeningNowActive="active"/>
                <HappeningNow/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Start/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation startActive="active"/>
                <Start/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/About/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation aboutActive="active"/>
                <About/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Contact/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation contactActive="active"/>
                <Contact/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Careers/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation careersActive="active"/>
                <Careers/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/ThankYou/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation/>
                <ThankYou/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Privacy/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation privacyActive="active"/>
                <Privacy/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Cookies/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation cookiesActive="active"/>
                <Cookies/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Site/:host"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation sitesActive="active"/>
                <Site match={match.match}/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Embed/"} children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <MyToast message={GetText('generic', 'useOfCookies')}/>
                <Navigation embedActive="active"/>
                <Embed/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Embed/WebsiteRank"} children={(match) => (
                <WebsiteRank/>
              )}/>

              <Route children={(match) => (
                <>
                <GoogleAnalytics></GoogleAnalytics>
                <Navigation/>
                <PageNotFound/>
                <Footer/>
                </>
              )} />

            </Switch>
            
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
