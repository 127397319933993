import React, {useEffect, useState} from 'react'
import { SendHttpRequest, SetDocumentTitleAndMeta } from '../Utils';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import StandardContent from '../comp/StandardContent'
import Error from '../comp/Error'
import { GetText } from '../Lang'

const handleSubmit = (event, data, setThank, setError) => {
    event.preventDefault();
    SendHttpRequest('Contact', { 
        method: "POST", 
        body: JSON.stringify(data)}
    ).then(function(data) {
        data.errorCode ? setError(true) : setThank(true);
    }).catch(() => setError(true));
}

const ThankYou = () => {
    return (
        <>
            <h1>{GetText('contact', 'headers', 'thankyou')}</h1>
            <p className="lead">{GetText('contact', 'explanation', 'thankyou')}</p>
        </>
    );
}
  
const Contact = () => {

  useEffect(() => SetDocumentTitleAndMeta('contact'));

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [thank, setThank] = useState(false);
  const [error, setError] = useState(false);

  return (
    <StandardContent>
    {thank ? <ThankYou/> : error ? <Error message={GetText('contact', 'explanation', 'error')}/> :    
      <Row>
        <Col md="8" className="mt-3">
          <h1>{GetText('contact', 'h1')}</h1>
          <Form onSubmit={(event) => handleSubmit(event, {
              name: name,
              email: email,
              message: message
          }, setThank, setError)}>
            
            <Form.Group controlId="formGridName">
                <Form.Label>{GetText('contact', 'labels', 'name')}</Form.Label>
                <Form.Control type="text" required placeholder={GetText('contact', 'placeholders', 'name')} value={name} onChange={(event) => setName(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="formGridEmail">
              <Form.Label>{GetText('contact', 'labels', 'email')}</Form.Label>
              <Form.Control type="email" required placeholder={GetText('contact', 'placeholders', 'email')} value={email} onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="formGridMessage">
                <Form.Label>{GetText('contact', 'labels', 'message')}</Form.Label>
                <Form.Control required as="textarea" rows="3" placeholder={GetText('contact', 'placeholders', 'message')} value={message} onChange={(event) => {setMessage(event.target.value)}}/>
            </Form.Group>

            <Button variant="primary" className="block-md" type="submit">{GetText('contact', 'buttons', 'submit')}</Button>

          </Form>
        </Col>
      </Row>
    }
    </StandardContent>
  );
}

export default Contact;