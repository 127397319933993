
import React from 'react'
import { Url } from '../Utils'
import { FormatString } from '../cutils'
import { GetText } from '../Lang'
import { Image } from 'react-bootstrap'
import { ChromeExtensionUrl } from '../Defines'

const Footer = () =>  {
    return (
        <>
            <hr className="mt-5"/>
            <section className="footer bg-light pt-5 pb-2">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5>{GetText('footer', 'col1', 'header')}</h5>
                            <ul className="list-unstyled">
                                <li><a href={Url('HappeningNow/')}>{GetText('footer', 'col1', 'happening')}</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>{GetText('footer', 'col2', 'header')}</h5>
                            <ul className="list-unstyled">
                                <li><a href={Url('About/')}>{GetText('footer', 'col2', 'about')}</a></li>
                                <li><a href={Url('Contact/')}>{GetText('footer', 'col2', 'contact')}</a></li>
                                <li><a href={Url('Careers/')}>{GetText('footer', 'col2', 'careers')}</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>{GetText('footer', 'col3', 'header')}</h5>
                            <ul className="list-unstyled">
                                <li><a href={Url('Privacy/')}>{GetText('footer', 'col3', 'privacy')}</a></li>
                                <li><a href={Url('Cookies/')}>{GetText('footer', 'col3', 'cookies')}</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <ul className="list-unstyled">
                                <li>
                                    <a href={ChromeExtensionUrl} target="_blank" rel="noopener noreferrer">
                                        <Image height={'40px'} src={'/images/available-chrome-store.png'}></Image>
                                    </a>
                                </li>
                                <li>
                                    <iframe title="W3Rated Rank" className="mt-4" frameBorder="0" width="138" height="110" src={window.location.origin + '/Embed/WebsiteRank'}>
                                    </iframe>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="text-center mt-2" dangerouslySetInnerHTML={{__html: FormatString(GetText('generic', 'copyright'), [new Date().getFullYear()])}}></p>
            </section>
        </>
    );
}

export default Footer;