
import React, { useEffect } from 'react'
import ReactGA from 'react-ga';

const GoogleAnalytics = (props) => {

    useEffect(() => {
        ReactGA.initialize('UA-155292771-1', {
            testMode: false
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (<></>);
}

export default GoogleAnalytics;
