import React, {useEffect} from 'react'
import { Url, SetDocumentTitleAndMeta } from '../Utils'
import StandardContent from '../comp/StandardContent'

const Privacy = () => {
  useEffect(() => SetDocumentTitleAndMeta('privacy'));
  return (
    <StandardContent>
        <h1>Privacy Policy</h1>
        
        <b>Last updated on December 1st, 2019</b><br/><br/>
        
        <p>At W3Rated we care about data privacy. This document explains what type of data we collect through our website and browser toolbar service and how do we store and process it.</p>

        <p>By using our website or browser toolbar extension (collectively, the "Service"), you consent to our Privacy Policy and agree to its Terms and Conditions. Please note that our terms and conditions are subject to change anytime. Hence make sure to check for updated versions of this page as frequently as possible.</p>

        <h4><b>Information We Store</b></h4>

        <p>Services we provide are mainly possible thanks to the information you give us in various ways. Below are types of information we collect:</p>

        <ul>
          <li>Information You Give Us Explicitly: We store and process data that you send us while using our services. For example, when you submit a website review by filling in an html form.</li>
          <li>Automatic Information Collection: While exchanging any kind of information thought our APIs, either by using our user interfaces or making direct HTTP requests to our servers, we collect certain type of additional information which includes general information about visitor’s geolocation and some browser specific information. We collect this information for (a) providing high quality services (b) for logging purposes and data analysis.</li>
        </ul>

        <h4><b>Log Files</b></h4>
        <p>W3Rated follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

        <h4><b>Cookies</b></h4>
        <p>Like any other website, W3Rated uses cookies. Consult our <a href={Url('Cookies')}>Cookie Policy</a> page to find out how do we use cookies and how to manage your cookie preferences.</p>

        <h4><b>Data Security</b></h4>
        <p>At W3Rated we use commonly know security practices and technological means to protect data and bring the risk of data loss, unauthorised access, misuse to nearly impossible.</p>
        <p>W3Rated does not sell or expose any user specific information to third parties.</p>

        <h4><b>Third Party Privacy Policies</b></h4>
        <p>Our privacy policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>

        <h4><b>Children's Information</b></h4>
        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. w3rated.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

        <h4><b>Consent</b></h4>
        <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>

    </StandardContent>
  );
}

export default Privacy;