import React, { useEffect } from 'react'
import SearchComponent from '../comp/SearchComponent'
import Hero from '../comp/Hero'
import StandardContent from '../comp/StandardContent'
import { SetDocumentTitleAndMeta } from '../Utils'
import { GetText } from '../Lang'
import Try from '../comp/Try'

const Search = (props) => {
  useEffect(() => SetDocumentTitleAndMeta('search'));
  return (
    <>
      <Hero>
        <h1>{GetText('search', 'h1')}</h1>
        <p className="lead">{GetText('search', 'lead')}</p>
      </Hero>
      <StandardContent>
        <SearchComponent 
          buttonLabel={GetText('search', 'buttons', 'search')}
          placeholderText={GetText('search', 'placeholders', 'search')}
          explanationText={GetText('search', 'explanation', 'search')}
          />
      </StandardContent>
      <Try/>
    </>
  );
}
  
export default Search;