import React, {useEffect} from 'react'
import StandardContent from '../comp/StandardContent'
import { SetDocumentTitleAndMeta } from '../Utils'
import Error from '../comp/Error'
import { GetText } from '../Lang'

const PageNotFound = () => {
  useEffect(() => SetDocumentTitleAndMeta('pageNotFound'));
  return (
    <StandardContent>
      <Error message={GetText('pageNotFound', 'pageNotFound')}/>
    </StandardContent>
  );
}

export default PageNotFound;