import { DefaultLang, GlobalHttpRequestTimeout } from './Defines'
import { HttpHost } from './cdefines.js'
import State from './State'
import { GetText } from './Lang'
import { FormatString } from './cutils';

export function SetDocumentTitleAndMeta(namespace, titlePlaceholders=undefined, descPlaceholders=undefined) {
    if (typeof titlePlaceholders === "undefined") {
        document.title = GetText(namespace, 'title');
        document.querySelector('meta[property="og:title"]').setAttribute("content", GetText(namespace, 'title'));
    }
    else {
        document.title = FormatString(GetText(namespace, 'title'), [titlePlaceholders]);
        document.querySelector('meta[property="og:title"]').setAttribute("content", FormatString(GetText(namespace, 'title'), [titlePlaceholders]));
    }
    if (typeof descPlaceholders === 'undefined') {
        document.querySelector('meta[name="description"]').setAttribute("content", GetText(namespace, 'description'));
        document.querySelector('meta[property="og:description"]').setAttribute("content", GetText(namespace, 'description'));
    }
    else {
        document.querySelector('meta[name="description"]').setAttribute("content", FormatString(GetText(namespace, 'description'), descPlaceholders));
        document.querySelector('meta[property="og:description"]').setAttribute("content", FormatString(GetText(namespace, 'description'), descPlaceholders));
    }
}

export function Url(page = '') {
    let query = '/';
    if (State.lang !== DefaultLang) {
        query += State.lang + '/';
    }
    query += page;
    return query;
}

export const SendHttpRequest = (url, settings = {}, timeout=GlobalHttpRequestTimeout) => {
    const requestSettings = Object.assign({}, settings, {
        headers: { 
            'Accept': 'application/json'
        }
    });
    if (typeof localStorage.session !== 'undefined') {
        requestSettings.headers['XWeb'] = true;
        requestSettings.headers['Token'] = localStorage.session;
    }
    return new Promise((resolve, reject) => {
        let didTimeOut = false;
        let timer = setTimeout(() => {
            didTimeOut = true;
            //ErrorPage(GetText('explanation', 'pageDataRetrievalError'))
            reject(new Error('Request timed out'));
        }, timeout);
        fetch(HttpHost + url, requestSettings)
        .then((response) => {
            clearTimeout(timer);
            if ((response.status === 200) && !didTimeOut) {
                response.json().then((data) => {
                    if (data.session) {
                        localStorage.session = data.session;
                    }
                    resolve(data);
                    /*
                    if (data.errorCode) {
                        switch (data.errorCode) {
                            case 'GRACE_PERIOD_NOT_FULFILLED':
                                var date = new Date(data.params[0] * 1000);
                                //ErrorPage(FormatString(GetText('explanation', 'alreadyRated'), [date.toLocaleString(), FormatSecondsToDaysHoursMinsSeconds(data.params[1])]));
                                break;
                            default:
                                ErrorPage(FormatString(GetText('explanation', data.errorCode), [data.params]));
                                break;
                        }
                    }
                    else {
                        resolve(data);
                    }*/
                }).catch(() => {
                    //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
                    reject(new Error('Unknown error occured'));
                });
            }
            else {
                //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
                reject(new Error('Request timed out'));
            }
        })
        .catch((err) => {
            if (didTimeOut) return;
            //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
            reject(err);
        });
    });
}

export const IdentifyEmbedHost = () => {
    var href = (window.location !== window.parent.location)
            ? document.referrer
            : "";
            
    
    return ParseHost(href);
}

export const ParseHost = (href) => {
    try {
        let url = new URL(href);
        if (url.protocol.startsWith('http')) {
            let host = url.host.replace('www.', '');
            if (/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/.test(host)) {
                return host;
            }
            else {
                return null;
            }
        }
    }
    catch (error) {
        return null;
    }
}