import React from 'react'
import { GetText } from '../Lang'
import { Button } from 'react-bootstrap'
import Hero from '../comp/Hero'
import StandardContent from '../comp/StandardContent'
import { FormatDecimal, FormatString } from '../cutils'
import { Url, SendHttpRequest, SetDocumentTitleAndMeta} from '../Utils'
import Spinner from '../comp/Spinner'
import BeepMp3 from '../misc/beep.mp3'

const MAX_CHUNK_LIMIT = 99;
const UPDATE_INTERVAL_MS = 10000;

const MapAverageScoreToAlertClass = (score) => {
  if (score <= 3) {
    return 'alert-danger';
  }
  if (score <= 5) {
    return 'alert-warning';
  }
  if (score <= 7) {
    return 'alert-info';
  }
  return 'alert-success';  
}

const SiteList = (props) => {
  return (
      props.data.map((item, index) => 
        <div key={index} className={"alert " + MapAverageScoreToAlertClass(item.averageRating)} role="alert">
          <h5>
            <p
              dangerouslySetInnerHTML={{__html: item.iso && false ? 
                FormatString(GetText('happening', 'explanations', 'someoneFromKnownLocationRated'), [
                  GetText('countries', item.iso),
                  Url('Site/' + item.site),
                  item.site,
                  FormatDecimal(item.averageRating)
                ])
                :
                FormatString(GetText('happening', 'explanations', 'someoneFromUnknowLocationRated'), [
                  Url('Site/' + item.site),
                  item.site,
                  FormatDecimal(item.averageRating)
                ])}}
            ></p>
          </h5>
          <div>
            <span className="text-muted text-right">{new Date(item.insertOn * 1000).toLocaleString()}</span>
          </div>
        </div>
      )
  )
}

class HappeningNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChunk: 0,
      data: [],
      isLoading: true,
      lastUpdated: new Date(),
      timerOn: true
    }
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  fetchAndAppendData() {
    let self = this;
    this.setState({
      isLoading: true
    });
    SendHttpRequest('GetHappeningNow', { method: "POST", body: JSON.stringify({
      chunk: this.state.currentChunk
    })}).then(function(resp) {
      if (
        (self.state.data.length !== 0) && 
        (self.state.data[0].id < resp.data[0].id)
      ) {
        self.setState({
          lastUpdated: new Date()
        });
        var audio = new Audio(BeepMp3);
        audio.play();
      }
      if (self.state.currentChunk > 0) {
        let clone = [...self.state.data];
        clone = clone.concat(resp.data);
        self.setState({data: clone});
      }
      else {
        self.setState({data: resp.data});
      }
      self.setState({
        hasMoreEntries: resp.hasMoreEntries ? true : false,
        isLoading: false
      });
    }).catch(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  initTimer() {
    this.setState({ timerOn: true });
    this.timer = setInterval(() => {
      this.fetchAndAppendData();
    }, UPDATE_INTERVAL_MS);
  }

  resetTimer() {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  }

  handleOnClick() {
    this.resetTimer();
    this.setState({currentChunk: this.state.currentChunk + 1}, () => {
      this.fetchAndAppendData();
    });
  }

  componentDidMount() {
    this.fetchAndAppendData();
    this.initTimer();
    SetDocumentTitleAndMeta('happening');
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  render() {
    return (
      <>
          <Hero>
              <h1>{GetText('happening', 'h1')}</h1>
              <p className="lead">{GetText('happening', 'lead')}</p>
          </Hero>
          <StandardContent>
            <div className="row">
              <div className="col-md-9">
                <h2>{this.state.timerOn ? 'Tracking Updates in Real-Time' : 'Past updates'}</h2>
                <p>
                  {FormatString(GetText('happening', 'explanations', 'lastUpdatedOn'), [new Date().toLocaleString(), this.state.timerOn ? `on` : `off`])}
                </p>
                {this.state.timerOn && <Spinner/>}
                <>
                  <SiteList data={this.state.data}/>
                  <div className="mt-3 text-center text-muted">
                    {FormatString(GetText('happening', 'explanations', 'displayingLastXEntries'), [this.state.data.length])}
                    {this.state.hasMoreEntries && (this.state.currentChunk < MAX_CHUNK_LIMIT) && ' ' + GetText('happening', 'explanations', 'clickToLoadMore')}
                  </div>
                  {
                    this.state.hasMoreEntries && (this.state.currentChunk < MAX_CHUNK_LIMIT) &&
                    <div className="my-3 text-center">
                        <Button
                          disabled={this.state.isLoading}
                          onClick={this.handleOnClick}
                        >{GetText('happening', 'buttons', this.state.isLoading ? 'loading' : 'loadMore')}</Button>
                    </div>
                  }
                </>
              </div>
              <div className="col-md-2">
                <div className='position-fixed'>
                  <h5>
                    {GetText('happening', 'headers', 'legends')}
                  </h5>
                  <ul className="list-group">
                    <li className="list-group-item list-group-item-success">{GetText('happening', 'legends', 'excellent')}</li>
                    <li className="list-group-item list-group-item-info">{GetText('happening', 'legends', 'good')}</li>
                    <li className="list-group-item list-group-item-warning">{GetText('happening', 'legends', 'average')}</li>
                    <li className="list-group-item list-group-item-danger">{GetText('happening', 'legends', 'poor')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </StandardContent>
      </>
    )
  }
}

export default HappeningNow;