import React from 'react'
import Alert from 'react-bootstrap/Alert'
import {useCookies} from 'react-cookie'

const MyToast = ({message}) => {
  const [cookie, setCookie] = useCookies(['cookie_consent'])
  return (!cookie['cookie_consent'] && <Alert variant="primary" className="m-0 row">
    <div className="container text-center">
      {message}
      <button type="button" className="close ml-2 mb-1" onClick={() => setCookie('cookie_consent', 1, {path: '/'})}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
    </div>
  </Alert>)
}

export default MyToast;