import React from 'react'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead' // ES2015
import { SendHttpRequest, Url } from '../Utils'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Redirect } from "react-router-dom"

class SearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            searchText: '',
            allowNew: false,
            isLoading: false,
            multiple: false,
            options: [],
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleSearch (query) {
        let self = this;
        this.setState({isLoading: true});
        SendHttpRequest('SearchWebsite', { method: "POST", body: JSON.stringify({
            host: query
        })}).then(function(data) {
            self.setState({
                isLoading: false,
                options: data.targets
            });
        }).catch(() => {});
    }

    onChange(object) {
        if (object.length === 1) {
            this.setState({ redirect: true, searchText: object[0].site });
        }
    }

    render(props) {
        return (
            (
            this.state.redirect ?
            <Redirect to={Url('site/' + this.state.searchText)}/>
            :
            <>
            <div className="my-4">
                <Row className="mb-2 justify-content-center">
                    <h5>{this.props.explanationText}</h5>
                </Row>
                <Row className="justify-content-center">
                    <Col md="5" className="mb-2 pr-md-0">
                        <AsyncTypeahead
                            {...this.state}
                            id="searchbar"
                            onBlur={(event) => {
                                this.setState({searchText:event.target.value});
                            }}
                            onChange={this.onChange}
                            className="mr-sm-2"
                            placeholder={this.props.placeholderText}
                            minLength={2}
                            autoFocus
                            labelKey="site"
                            onSearch={this.handleSearch}
                            />
                    </Col>
                    <Col md="auto" className="mb-2 pl-md-0">
                        <Button 
                            block variant="primary" 
                            type="submit"
                            onClick={() => {}}
                            >
                            {this.props.buttonLabel}
                        </Button>
                    </Col>
                </Row>
                <br/>
            </div>
            </>
            )
        );
    }
}

export default SearchComponent;