import React, {useEffect} from 'react'
import { SetDocumentTitleAndMeta, Url } from '../Utils'
import { GetText } from '../Lang'
import Hero from '../comp/Hero'
import { Button } from 'react-bootstrap'

const Careers = () => {
  useEffect(() => SetDocumentTitleAndMeta('careers'));
  return (
    <Hero>
      <h1>{GetText('careers', 'h1')}</h1>
      <p className="lead">{GetText('careers', 'lead')}</p>
      <Button href={Url('Search')}>{GetText('careers', 'buttons', 'search')}</Button>
    </Hero>
  );
}

export default Careers;