export const FormatNumberOfViews = (numberOfViews) => {
    if (numberOfViews > 999999) {
        return parseFloat((numberOfViews / 1000000).toFixed(1)) + 'm';
    }
    if (numberOfViews > 999) {
        return parseFloat((numberOfViews / 1000).toFixed(1)) + 'k';
    }
    return numberOfViews;
}

export const FormatDecimal = (number) => {
    return parseFloat(number.toFixed(1));
}

export const FormatString = (label, values) => {
    for (var i=0; i < values.length; i++) {
        label = label.replace("{" + i + "}", values[i]);
    }
    return label;
}