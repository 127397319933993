import React from 'react'
import { Image, Button } from 'react-bootstrap'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

const ImageTextRow = (props) => {
  const {imgColMd=6, textColMd=6, reversed, imageProps, header, lead, button=false, buttonAttr={}, buttonText=''} = props;
  return (
    <div className="row my-5">
          <Fade {...reversed ? {right: true} : {left: true}} >
            <div className={'col-md-' + imgColMd + (reversed ? ' order-1' : ' order-0')}>
                  <Image {...imageProps}/>
            </div>
          </Fade>
          <Zoom>
            <div className={"py-9 col-md-" + textColMd}>
                <h3>{header}</h3>
                <p className="lead text-justify">{lead}</p>
                {button && <Button {...buttonAttr}>{buttonText}</Button>}
            </div>
          </Zoom>
    </div>);
}

export default ImageTextRow;